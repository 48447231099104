import { AddButton, Datagrid, FoodSelector } from 'components/common'
import { FoodEditModal } from 'components/dialogs'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addDataItem, deleteDataItem, selectDayInfo, updateDataItem } from 'store/diary.slice'
import { selectDiaryFilter } from 'store/filters.slice'

const Diary = () => {
  const dispatch = useDispatch()

  const filter = useSelector(selectDiaryFilter)
  const model = useSelector(selectDayInfo)

  const [selectedFoodItem, setSelectedFoodItem] = useState(null)

  const [focus, setFocus] = useState(true)
  useEffect(() => setFocus(!selectedFoodItem), [selectedFoodItem])

  const update = async (item, value) => {
    if (value === undefined || value === null || value < 0) return false

    if (value === 0) {
      await dispatch(deleteDataItem(item.id))
    } else {
      await dispatch(updateDataItem({ ...item, weight: value }))
    }
  }

  const onUpdate = async (item, value) => {
    if (filter.isToday) await update(item, item.weight + value)
    else await dispatch(addDataItem({ foodItemId: item.foodItemId, weight: value }))
  }

  const onFoodItemSelected = async (foodItem, value) => {
    const existing = filter.isToday && model.find(x => x.foodItemId === foodItem.id)
    if (existing) {
      await update(existing, existing.weight + value)
    } else {
      await dispatch(addDataItem({ foodItemId: foodItem.id, weight: value }))
    }
  }

  const onAdd = foodItem => {
    if (!foodItem.id) setSelectedFoodItem(foodItem)
  }

  const closeFoodEditModal = useCallback(() => setSelectedFoodItem(null), [])

  const config = [
    {
      id: 'name',
      title: 'Name',
      type: 'cell-name pl-1',
      content: x => (
        <div className='is-pointer is-hover-underlined' onClick={() => setSelectedFoodItem(x.foodItem)}>
          {x.name}
        </div>
      ),
      sort: x => x.name,
      enabled: true,
      optional: false,
    },
    {
      id: 'calories',
      title: 'Cal',
      desc: 'Calories, Fat / Protein / Carbohydrate',
      type: 'cell-name pl-0',
      content: x => (
        <>
          <div>{x.calories}</div>
          <div className='is-white-space-nowrap is-size-7'>
            {x.protein} / {x.fat} / {x.carbohydrate}
          </div>
        </>
      ),
      sort: x => x.calories || '',
      enabled: true,
      optional: false,
    },
    {
      id: 'weight',
      title: 'W(g)',
      desc: 'Weigth',
      type: 'cell-name pl-0',
      content: x => <span>{x.weight}</span>,
      sort: x => x.weight,
      enabled: true,
      optional: false,
    },
    {
      id: 'add',
      title: '',
      type: 'cell-name pl-0 pr-1',
      content: x => (
        <div className='is-pulled-right'>
          <AddButton onAdd={v => onUpdate(x, v)} />
        </div>
      ),
      enabled: true,
    },
  ]

  return (
    <>
      <section className='section pt-3 pl-3 pr-3 pb-0'>
        <FoodSelector onSelected={onFoodItemSelected} onAdd={onAdd} autoFocus={focus} />
      </section>

      <section className='section p-3'>
        <div className='box pl-2 pr-2'>
          <Datagrid model={model} config={config} />
        </div>

        {selectedFoodItem && <FoodEditModal item={selectedFoodItem} onClose={closeFoodEditModal} />}
      </section>
    </>
  )
}

export default Diary
