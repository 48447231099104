import { useSelector } from 'react-redux'
import { selectIsLoading } from 'store'

const Loader = () => {
  const isLoading = useSelector(selectIsLoading)

  return isLoading && <progress className='progress is-small is-info is-radiusless'></progress>
}

export default Loader
