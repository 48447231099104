import axios from 'axios'

const API_URL = '/api/refs'

const getFoodTypes = async () => {
  const response = await axios.get(`${API_URL}/food-types`)
  return response.data
}

const getTimeZones = async () => {
  const response = await axios.get(`${API_URL}/time-zones`)
  return response.data
}

const referencesService = { getFoodTypes, getTimeZones }

export default referencesService
