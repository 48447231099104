import axios from 'axios'

const API_URL = '/api/settings'

const getSettings = async () => {
  const response = await axios.get(API_URL)
  return response.data
}

const settingsService = { getSettings }

export default settingsService
