import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'

const FaIcon = ({ icon, isSmall, className }) => {
  return (
    <span className={`icon ${isSmall ? 'is-small' : ''} ${className}`}>
      <FontAwesomeIcon icon={icon} />
    </span>
  )
}

FaIcon.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  isSmall: PropTypes.bool,
  className: PropTypes.string,
}

export default FaIcon
