import { useSelector } from 'react-redux'
import { selectVersion } from 'store/settings.slice'

const Footer = () => {
  const version = useSelector(selectVersion)

  return (
    <footer className='footer'>
      <div className='content has-text-centered'>
        <p>
          <strong>Food Diary</strong>
          <span> v{version}</span>
        </p>
      </div>
    </footer>
  )
}

export default Footer
