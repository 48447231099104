import { useSelector } from 'react-redux'
import { selectDayStatistics } from 'store/statistics.slice'

const DayStats = () => {
  const stats = useSelector(selectDayStatistics)
  const model = [
    { name: 'Calories', value: stats.calories, percent: stats.caloriesPercent },
    { name: 'Protein', value: stats.protein, percent: stats.proteinPercent, unit: 'g' },
    { name: 'Fat', value: stats.fat, percent: stats.fatPercent, unit: 'g' },
    { name: 'Carbs', value: stats.carbohydrate, percent: stats.carbohydratePercent, unit: 'g' },
  ]

  return (
    <nav className='level is-mobile'>
      {model.map(x => (
        <div key={x.name} className='level-item has-text-centered'>
          <div>
            <p className='heading'>
              {x.name} {x.percent || 0}%
            </p>
            <p className='title'>
              {x.value}
              <span className='is-size-6'>{x.unit}</span>
            </p>
          </div>
        </div>
      ))}
    </nav>
  )
}

export default DayStats
