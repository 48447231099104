import { Button } from 'components/core'
import { useAuth } from 'providers'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { selectProfileStatus } from 'store/profile.slice'
import { isEmpty } from 'utils/helpers'

const LogIn = () => {
  const { logIn } = useAuth()
  const status = useSelector(selectProfileStatus)

  const [state, setState] = useState({ username: '', password: '', persistent: true })
  const [errors, setErrors] = useState({})

  const validate = () => {
    var result = []

    Object.keys(state).forEach(x => {
      if (isEmpty(state[x])) result.push({ code: x, error: 'Field is required' })
    })

    const hasErrors = result.length > 0
    if (!hasErrors) setErrors({})
    else {
      const e = result.reduce((acc, x) => {
        acc[x.code] = x.error
        return acc
      }, {})
      setErrors(e)
    }

    return !hasErrors
  }

  const handleLogin = () => {
    if (validate()) logIn(state.username, state.password, state.persistent)
  }

  const field = (name, label, value, type, autoFocus = false, autoComplete = null) => (
    <div className='field'>
      <label className='label'>{label}</label>
      <div className='control'>
        <input
          name={name}
          className='input'
          type={type}
          value={value || ''}
          onChange={e => setState({ ...state, [name]: e.target.value })}
          onKeyUp={e => e.code === 'Enter' && handleLogin()}
          autoFocus={autoFocus}
          autoComplete={autoComplete}
        />
      </div>
      {errors[name] && <p className='help is-danger'>{errors[name]}</p>}
    </div>
  )

  return (
    <div className='block'>
      {field('username', 'Email', state.username, 'text', true, 'on')}
      {field('password', 'Password', state.password, 'password')}

      <div className='field is-grouped'>
        <div className='field is-fullwidth'>
          <label className='checkbox'>
            <input
              className='mr-2'
              type='checkbox'
              checked={state.persistent}
              onChange={e => setState({ ...state, persistent: e.target.checked })}
            />
            Remember me
          </label>
        </div>
        <div className='field'>
          <Button
            className={`is-info ${status === 'loading' ? 'is-loading' : ''}`}
            content='Log In'
            onClick={handleLogin}
          />
        </div>
      </div>
    </div>
  )
}

export default LogIn
