import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { diaryService } from 'services'
import { emptyArray, hasEmpty } from 'utils/helpers'
import { selectDiaryFilter } from './filters.slice'
import { selectFood } from './food.slice'

const diaryDataAdapter = createEntityAdapter()

const initialState = {
  data: diaryDataAdapter.getInitialState(),
  status: 'idle',
  error: null,
}

export const fetchData = createAsyncThunk('diary/fetch', async date => diaryService.getItems(date))

export const addDataItem = createAsyncThunk('diary/add', async (item, api) => {
  const added = await diaryService.addItem(item)
  return [added, selectDiaryFilter(api.getState()).isToday]
})

export const updateDataItem = createAsyncThunk('diary/update', async x => diaryService.updateItem(x))

export const deleteDataItem = createAsyncThunk('diary/delete', async id => diaryService.deleteItem(id))

const slice = createSlice({
  name: 'diary',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        diaryDataAdapter.removeAll(state.data)
        diaryDataAdapter.upsertMany(state.data, action.payload)
      })
      .addCase(addDataItem.fulfilled, (state, action) => {
        state.status = 'succeeded'
        const [item, updateState] = action.payload
        if (updateState) diaryDataAdapter.upsertOne(state.data, item)
      })
      .addCase(updateDataItem.fulfilled, (state, action) => {
        state.status = 'succeeded'
        diaryDataAdapter.upsertOne(state.data, action.payload)
      })
      .addCase(deleteDataItem.fulfilled, (state, action) => {
        state.status = 'succeeded'
        diaryDataAdapter.removeOne(state.data, action.payload)
      })
      .addMatcher(
        isAnyOf(fetchData.pending, addDataItem.pending, updateDataItem.pending, deleteDataItem.pending),
        state => {
          state.status = 'loading'
        },
      )
      .addMatcher(
        isAnyOf(fetchData.rejected, addDataItem.rejected, updateDataItem.rejected, deleteDataItem.rejected),
        (state, action) => {
          state.status = 'failed'
          state.error = action.error
        },
      )
  },
})

export default slice.reducer

export const selectDataStatus = createSelector(
  state => state.diary.status,
  status => status,
)

export const selectDataError = createSelector(
  state => state.diary.error,
  error => error,
)

export const { selectAll: selectAllDayData, selectById: selectDayDataById } = diaryDataAdapter.getSelectors(
  state => state.diary.data,
)

export const selectDayData = state => state.diary.data.entities

export const selectDayInfo = createSelector([selectFood, selectAllDayData], (food, data) => {
  if (hasEmpty(food, data)) return emptyArray

  return data.map(x => {
    const foodItem = food[x.foodItemId]

    const calories = Math.round((x.weight * foodItem?.calories) / 100)
    const protein = Math.round((x.weight * foodItem?.protein) / 100)
    const fat = Math.round((x.weight * foodItem?.fat) / 100)
    const carbohydrate = Math.round((x.weight * foodItem?.carbohydrate) / 100)

    return { ...x, name: foodItem?.name, calories, protein, fat, carbohydrate, foodItem }
  })
})
