import { Button } from 'components/core'
import { FoodEditModal } from 'components/dialogs'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addFoodItem, selectFood, selectFoodStatus, updateFoodItem } from 'store/food.slice'
import { selectAllFoodTypes, selectFoodTypes, selectMealType } from 'store/references.slice'
import AddButton from '../add-button/add-button'
import FoodSelector from '../food-selector/food-selector'

const FoodEdit = ({ id, name, typeId, onClose }) => {
  const dispatch = useDispatch()

  const allFoodTypes = useSelector(selectAllFoodTypes)
  const foodTypes = useSelector(selectFoodTypes)
  const food = useSelector(selectFood)
  const status = useSelector(selectFoodStatus)
  const mealType = useSelector(selectMealType)

  const getEmptyFoodItem = name => ({ id: '', name, typeId: typeId || allFoodTypes[0].id, parts: [] })
  const [foodItem, setFoodItem] = useState(food[id] || getEmptyFoodItem(name))

  const initState = useCallback(
    item => ({
      ...item,
      type: foodTypes[item?.typeId],
      parts: item.parts.map(x => ({ ...x, foodItem: food[x.foodItemId] })),
    }),
    [foodTypes, food],
  )

  const [state, setState] = useState(initState(foodItem))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setState(initState(foodItem)), [foodItem])

  const [selectedFoodItem, setSelectedFoodItem] = useState(null)
  const [isMultiple, setIsMultiple] = useState(false)

  const nameInput = useRef(null)

  const onFoodTypeSelected = e => {
    const selected = allFoodTypes.find(x => x.name === e.target.value)
    setState({ ...state, typeId: selected.id, type: selected })
  }

  const hasPartsChanges = (o, n) =>
    o?.length !== n?.length ||
    o.some(x => n.every(y => y.foodItemId !== x.foodItemId)) ||
    o.some(x => n.some(y => y.foodItemId === x.foodItemId && y.weight !== x.weight))

  const hasChanges = () =>
    state.name?.length &&
    (!foodItem.id ||
      foodItem.name !== state.name ||
      foodItem.alias !== state.alias ||
      foodItem.typeId !== state.typeId ||
      foodItem.calories != state.calories ||
      foodItem.protein != state.protein ||
      foodItem.fat != state.fat ||
      foodItem.carbohydrate != state.carbohydrate ||
      foodItem.imageUrl != state.imageUrl ||
      (state.typeId === mealType.id && hasPartsChanges(foodItem.parts, state.parts)))

  const handleSubmit = async () => {
    if (hasChanges()) {
      const payload = {
        ...foodItem,
        name: state.name,
        alias: state.alias,
        typeId: state.typeId,
        calories: parseFloat(state.calories) || 0,
        protein: parseFloat(state.protein) || 0,
        fat: parseFloat(state.fat) || 0,
        carbohydrate: parseFloat(state.carbohydrate) || 0,
        imageUrl: state.imageUrl,
        parts: state.parts.map(x => {
          return {
            foodItemId: x.foodItemId,
            weight: x.weight,
          }
        }),
      }

      if (!foodItem.id) await dispatch(addFoodItem(payload))
      else await dispatch(updateFoodItem(payload))

      if (!isMultiple) onClose()
      else {
        setFoodItem(getEmptyFoodItem(''))
        nameInput.current.focus()
      }
    }
  }

  const calculateDetails = parts => {
    const sum = (items, v) => items.reduce((acc, x) => acc + ((v(x) || 0) * x.weight) / 100, 0)
    const weight = parts.reduce((acc, x) => acc + x.weight, 0)

    return {
      calories: +((sum(parts, x => x.foodItem.calories) * 100) / weight).toFixed(1),
      protein: +((sum(parts, x => x.foodItem.protein) * 100) / weight).toFixed(1),
      fat: +((sum(parts, x => x.foodItem.fat) * 100) / weight).toFixed(1),
      carbohydrate: +((sum(parts, x => x.foodItem.carbohydrate) * 100) / weight).toFixed(1),
    }
  }

  const update = (item, value) => {
    if (value === undefined || value === null || value < 0) return false

    const parts =
      value === 0
        ? [...state.parts.filter(x => x !== item)]
        : [...state.parts.map(x => (x !== item ? x : { ...x, weight: value }))]

    const details = calculateDetails(parts)
    setState({ ...state, ...details, parts: parts })
  }

  const onFoodItemSelected = (foodItem, value) => {
    const existing = state.parts.find(x => x.foodItemId === foodItem.id)
    if (existing) {
      update(existing, existing.weight + value)
    } else {
      const parts = [...state.parts, { foodItemId: foodItem.id, weight: value, foodItem }]
      const details = calculateDetails(parts)
      setState({
        ...state,
        ...details,
        parts: parts,
      })
    }
  }

  const onAdd = foodItem => {
    if (!foodItem.id) setSelectedFoodItem(foodItem)
  }

  const closeFoodEditModal = useCallback(() => setSelectedFoodItem(null), [])

  const buttons = () => (
    <div className='is-flex is-align-items-center is-justify-content-space-between'>
      <div>
        {!id && (
          <label className='checkbox'>
            <input className='mr-2' type='checkbox' checked={isMultiple} onChange={() => setIsMultiple(!isMultiple)} />
            Multiple
          </label>
        )}
      </div>
      <div className='buttons'>
        <Button
          className={`is-info ${status === 'loading-modal' ? 'is-loading' : ''}`}
          content='Save changes'
          onClick={handleSubmit}
        />
        <Button content='Cancel' onClick={onClose} />
      </div>
    </div>
  )

  const field = (name, label, value, autoFocus = false) => {
    const props = autoFocus ? { ref: nameInput } : {}
    return (
      <div className='field'>
        <label className='label'>{label}</label>
        <div className='control'>
          <input
            className='input'
            type='text'
            value={value || ''}
            onChange={e => setState({ ...state, [name]: e.target.value })}
            onKeyUp={e => e.code === 'Enter' && handleSubmit()}
            autoFocus={autoFocus}
            {...props}
          />
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className='block'>
        {field('name', 'Name', state.name, true)}
        {field('alias', 'Alias', state.alias)}

        <div className='field is-grouped'>
          <div className='field-body'>
            <div className='field is-fullwidth'>
              <label className='label'>Type</label>
              <div className='control'>
                <div className='select is-fullwidth'>
                  <select value={state.type?.name || ''} onChange={onFoodTypeSelected}>
                    {allFoodTypes.map(x => (
                      <option key={x.id}>{x.name}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            {field('calories', 'Calories', state.calories)}
          </div>
        </div>

        <div className='field is-grouped'>
          <div className='field-body'>
            {field('protein', 'Protein', state.protein)}
            {field('fat', 'Fat', state.fat)}
            {field('carbohydrate', 'Carbohydrate', state.carbohydrate)}
          </div>
        </div>

        {state.typeId === mealType.id && (
          <div className='field'>
            {field('imageUrl', 'Image URL', state.imageUrl)}

            <label className='label'>Ingredients</label>

            <FoodSelector onSelected={onFoodItemSelected} onAdd={onAdd} />

            {state.parts.map(part => (
              <div
                key={part.foodItemId}
                className='is-flex is-justify-content-space-between is-align-items-center mb-1'>
                <div>{part.foodItem.name}</div>
                <div className='is-flex is-align-items-center'>
                  <div className='mr-2'>
                    <abbr
                      title={`Calories: ${part.foodItem.calories}, Protein: ${part.foodItem.protein}, Fat: ${part.foodItem.fat}, Carbohydrate: ${part.foodItem.carbohydrate}`}>
                      {part.weight}g
                    </abbr>
                  </div>
                  <AddButton onAdd={v => update(part, part.weight + v)} />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {buttons()}

      {selectedFoodItem && <FoodEditModal item={selectedFoodItem} onClose={closeFoodEditModal} />}
    </div>
  )
}

FoodEdit.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  typeId: PropTypes.number,
  onClose: PropTypes.func,
}

export default FoodEdit
