import axios from 'axios'

const API_URL = '/api/diary'

const getItems = async date => {
  const response = await axios.get(`${API_URL}/${date}`)
  return response.data
}

const addItem = async item => {
  const response = await axios.post(API_URL, item)
  return response.data
}

const updateItem = async item => {
  const response = await axios.put(API_URL, item)
  return response.data
}

const deleteItem = async id => {
  await axios.delete(`${API_URL}/${id}`)
  return id
}

const diaryService = { addItem, deleteItem, getItems, updateItem }

export default diaryService
