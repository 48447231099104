import { FoodEdit } from 'components/common'
import { Modal } from 'components/core'
import PropTypes from 'prop-types'

const FoodEditModal = ({ item, onClose }) => {
  return (
    <Modal
      isActive={item ? true : false}
      onClose={onClose}
      title={`${item.id ? 'Edit' : 'Add'} food item`}
      content={<FoodEdit id={item.id} name={item.name} typeId={item.typeId} onClose={onClose} />}
    />
  )
}

FoodEditModal.propTypes = {
  item: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default FoodEditModal
