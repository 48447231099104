import { Datagrid, FilterTabs, Searchbox } from 'components/common'
import { Button } from 'components/core'
import { FoodEditModal } from 'components/dialogs'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { foodFilterChanged, selectFoodFilter } from 'store/filters.slice'
import { selectFoodInfo } from 'store/food.slice'
import { selectAllFoodTypes } from 'store/references.slice'
import { capitalize } from 'utils/helpers'

const Food = () => {
  const dispatch = useDispatch()

  const foodInfo = useSelector(selectFoodInfo)
  const filter = useSelector(selectFoodFilter)
  const foodTypes = useSelector(selectAllFoodTypes)

  const [selectedFoodItem, setSelectedFoodItem] = useState(null)

  const [focus, setFocus] = useState(true)
  useEffect(() => setFocus(!selectedFoodItem), [selectedFoodItem])

  const closeFoodEditModal = useCallback(() => setSelectedFoodItem(null), [])

  const model = foodInfo.filter(
    x =>
      (filter.type === null || filter.type === undefined || x.typeId === filter.type) &&
      (!filter.searchCriteria?.length ||
        x.nameLower?.includes(filter.searchCriteria) ||
        x.aliasLower?.includes(filter.searchCriteria)),
  )

  const [config] = useState([
    {
      id: 'name',
      title: 'Name',
      type: 'cell-name pl-1',
      content: x => (
        <div className='is-pointer is-hover-underlined' onClick={() => setSelectedFoodItem(x)}>
          {x.name}
        </div>
      ),
      sort: x => x.name,
      enabled: true,
      optional: false,
    },
    {
      id: 'calories',
      title: 'Cal',
      type: 'cell-name pl-0',
      content: x => <span>{x.calories}</span>,
      sort: x => x.calories || '',
      enabled: true,
      optional: false,
    },
    {
      id: 'protein',
      title: 'P',
      desc: 'Protein',
      type: 'cell-number pl-0',
      content: x => <span>{x.protein}</span>,
      sort: x => x.protein,
      enabled: true,
      optional: false,
    },
    {
      id: 'fat',
      title: 'F',
      desc: 'Fat',
      type: 'cell-number pl-0',
      content: x => <span>{x.fat}</span>,
      sort: x => x.fat || '',
      enabled: true,
    },
    {
      id: 'carbohydrate',
      title: 'C',
      desc: 'Carbohydrate',
      type: 'cell-number pl-0 pr-1',
      content: x => <span>{x.carbohydrate}</span>,
      sort: x => x.carbohydrate || '',
      enabled: true,
    },
  ])

  const onAdd = () => {
    const existing = model.find(x => x.nameLower === filter.searchCriteria)
    setSelectedFoodItem(existing || { name: capitalize(filter.searchCriteria) })
  }

  return (
    <section className='section p-3'>
      <nav className='panel'>
        <div className='panel-block'>
          <Searchbox
            value={filter.searchCriteria}
            onChange={x => dispatch(foodFilterChanged({ ...filter, searchCriteria: x }))}
            onEnterKeyUp={onAdd}
            autoFocus={focus}
          />
          <Button className='ml-2' content='Add' onClick={onAdd} />
        </div>

        <FilterTabs
          items={[{ id: null, name: 'All' }, ...foodTypes]}
          getId={x => x.id}
          getContent={x => x.name}
          selectedItem={foodTypes.find(i => i.id === filter.type)}
          onSelected={x => dispatch(foodFilterChanged({ ...filter, type: x.id }))}
        />

        <div className='panel-block'>
          <Datagrid model={model} config={config} totalCount={foodInfo.length} />
        </div>

        {selectedFoodItem && <FoodEditModal item={selectedFoodItem} onClose={closeFoodEditModal} />}
      </nav>
    </section>
  )
}

export default Food
