import { Button } from 'components/core'
import PropTypes from 'prop-types'
import { useState } from 'react'

const AddButton = ({ onAdd }) => {
  const [state, setState] = useState('')

  const handleAdd = () => {
    if (!state) return
    onAdd(state)
    setState('')
  }

  return (
    <div className='field has-addons button-field'>
      <div className='control'>
        <input
          className='input is-small'
          type='number'
          value={state}
          onChange={e => setState(+e.target.value)}
          onKeyUp={e => e.code === 'Enter' && handleAdd()}
        />
      </div>
      <Button className='is-small' icon='plus' onClick={handleAdd} />
    </div>
  )
}

AddButton.defaultProps = {
  onAdd: () => {},
}

AddButton.propTypes = {
  onAdd: PropTypes.func.isRequired,
}

export default AddButton
