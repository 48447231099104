import { useEffect } from 'react'
import toast, { ToastBar, Toaster } from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { selectDataError } from 'store/diary.slice'
import { selectFoodError } from 'store/food.slice'
import { selectProfileError } from 'store/profile.slice'
import { selectReferencesError } from 'store/references.slice'

const NotificationBar = () => {
  const profileError = useSelector(selectProfileError)
  const referencesError = useSelector(selectReferencesError)
  const foodError = useSelector(selectFoodError)
  const dataError = useSelector(selectDataError)

  useEffect(() => {
    const errors = [profileError, referencesError, foodError, dataError]
    errors.filter(x => x).forEach(x => toast.error(x.message, { id: x.code }))
  }, [profileError, referencesError, foodError, dataError])

  return (
    <Toaster
      toastOptions={{
        position: 'bottom-center',
        className: 'notification is-info is-light',
        error: { className: 'notification is-danger is-light', duration: 10000 },
        success: { className: 'notification is-success is-light' },
        loading: { className: 'notification is-warning is-light' },
      }}>
      {t => (
        <ToastBar toast={t}>
          {({ message }) => (
            <div className='is-flex is-align-items-center'>
              <span>{message}</span>
              <button className='delete' onClick={() => toast.dismiss(t.id)}></button>
            </div>
          )}
        </ToastBar>
      )}
    </Toaster>
  )
}

export default NotificationBar
