import { useDispatch, useSelector } from 'react-redux'
import { diaryFilterChanged, selectDiaryFilter } from 'store/filters.slice'
import { addDays, isToday } from 'utils/helpers'

const DateSelector = () => {
  const dispatch = useDispatch()

  const filter = useSelector(selectDiaryFilter)

  const handleSelectedDateChanged = date =>
    dispatch(diaryFilterChanged({ ...filter, selectedDate: date, isToday: isToday(date) }))

  const goBack = () => handleSelectedDateChanged(addDays(filter.selectedDate, -1))
  const goForward = () => handleSelectedDateChanged(addDays(filter.selectedDate, 1))

  return (
    <>
      <a className={`mr-1 p-2 is-pointer ${!filter.selectedDate ? 'is-invisible' : ''}`} onClick={goBack}>
        &lt;
      </a>
      <input
        type='date'
        className='has-text-white'
        value={filter.selectedDate}
        onChange={x => handleSelectedDateChanged(x.target.value)}
      />
      <a className={`ml-1 p-2 is-pointer ${filter.isToday ? 'is-invisible' : ''}`} onClick={goForward}>
        &gt;
      </a>
    </>
  )
}

export default DateSelector
