import { Navbar, Tabs } from 'components/common'
import { DayStats } from 'components/views'
import { useAuth } from 'providers'
import { useSelector } from 'react-redux'
import { selectTodaysLevel } from 'store/statistics.slice'

const Hero = () => {
  const { isAuthenticated } = useAuth()
  const level = useSelector(selectTodaysLevel)

  return (
    <section className={`hero is-small is-${level}`}>
      <div className='hero-head'>
        <Navbar />
      </div>
      {isAuthenticated && (
        <>
          <div className='hero-body p-4'>
            <DayStats />
          </div>
          <div className='hero-foot'>
            <Tabs />
          </div>
        </>
      )}
    </section>
  )
}

export default Hero
