import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { Button } from '..'

const Modal = ({ isActive, onClose, title, content }) => {
  useEffect(() => {
    const handle = e => {
      if (e.keyCode === 27) {
        e.stopPropagation()
        onClose()
      }
    }

    window.addEventListener('keyup', handle)
    return () => window.removeEventListener('keyup', handle)
  }, [onClose])

  const getTitle = () => (
    <div className='level is-align-items-baseline'>
      <span className='subtitle'>{title}</span>
      <Button className='is-link is-inverted' icon='times' onClick={() => onClose()} />
    </div>
  )

  return (
    <div className={`modal ${isActive && 'is-active'}`}>
      <div className='modal-background' onClick={onClose}></div>
      <div className='modal-content box'>
        {title && getTitle()}
        {content}
      </div>
      <button className='modal-close is-large' aria-label='close' onClick={onClose}></button>
    </div>
  )
}

Modal.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  content: PropTypes.element,
  buttons: PropTypes.element,
}

export default Modal
