import { Searchbox } from 'components/common'
import { Button } from 'components/core'
import { FoodEditModal } from 'components/dialogs'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { mealFilterChanged, selectMealFilter } from 'store/filters.slice'
import { selectMealInfo } from 'store/food.slice'
import { selectMealType } from 'store/references.slice'
import { capitalize } from 'utils/helpers'

const Meal = () => {
  const dispatch = useDispatch()

  const mealInfo = useSelector(selectMealInfo)
  const filter = useSelector(selectMealFilter)
  const mealType = useSelector(selectMealType)

  const [selectedFoodItem, setSelectedFoodItem] = useState(null)

  const [focus, setFocus] = useState(true)
  useEffect(() => setFocus(!selectedFoodItem), [selectedFoodItem])

  const model = mealInfo.filter(
    x =>
      !filter.searchCriteria?.length ||
      x.nameLower?.includes(filter.searchCriteria) ||
      x.aliasLower?.includes(filter.searchCriteria),
  )

  const onAdd = () => {
    const existing = model.find(x => x.nameLower === filter.searchCriteria)
    setSelectedFoodItem(existing || { name: capitalize(filter.searchCriteria), typeId: mealType.id })
  }

  const closeFoodEditModal = useCallback(() => setSelectedFoodItem(null), [])

  return (
    <>
      <section className='section pt-3 pl-3 pr-3 pb-0'>
        <nav className='panel'>
          <div className='panel-block'>
            <Searchbox
              value={filter.searchCriteria}
              onChange={x => dispatch(mealFilterChanged({ ...filter, searchCriteria: x }))}
              onEnterKeyUp={onAdd}
              autoFocus={focus}
            />
            <Button className='ml-2' content='Add' onClick={onAdd} />
          </div>
        </nav>
      </section>

      <section className='section meals p-3'>
        <div className='grid'>
          {model.map(item => (
            <div key={item.id} className='card'>
              <div className='card-content'>
                <div className='media'>
                  <div className='media-left'>
                    <figure className='image is-72x72'>
                      <img src={item.imageUrl || 'https://bulma.io/images/placeholders/96x96.png'} alt='Meal image' />
                    </figure>
                  </div>
                  <div className='media-content'>
                    <p className='title is-4'>{item.name}</p>
                    <div className='subtitle is-6 mb-1'>
                      <p className='subtitle is-6 mb-1'>
                        100g contains: <b>{item.calories} Calories</b>
                      </p>
                      <p className='subtitle is-6 mb-0'>
                        Protein: <b>{item.protein}g</b>, Fat: <b>{item.fat}g</b>, Carbohydrate:{' '}
                        <b>{item.carbohydrate}g</b>
                      </p>
                    </div>
                    <time dateTime={item.createdDate.toLocaleString()} className='is-size-7'>
                      {item.createdDate.toLocaleString()}
                    </time>
                  </div>
                </div>
                <div className='content'>
                  {item.parts.map(part => (
                    <div key={part.foodItemId} className='is-flex is-justify-content-space-between'>
                      <div>{part.name}</div>
                      <div>
                        <abbr
                          title={`Calories: ${part.calories}, Protein: ${part.protein}, Fat: ${part.fat}, Carbohydrate: ${part.carbohydrate}`}>
                          {part.weight}g
                        </abbr>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <footer className='card-footer'>
                <a href='#' className='card-footer-item' onClick={() => setSelectedFoodItem(item)}>
                  Edit
                </a>
                <a href='#' className='card-footer-item'>
                  Delete
                </a>
              </footer>
            </div>
          ))}
        </div>

        {selectedFoodItem && <FoodEditModal item={selectedFoodItem} onClose={closeFoodEditModal} />}
      </section>
    </>
  )
}

export default Meal
