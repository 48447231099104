import { UploadFood } from 'components/common'
import { Modal } from 'components/core'
import PropTypes from 'prop-types'

const UploadFoodModal = ({ isActive, onClose }) => {
  return (
    <Modal
      isActive={isActive}
      onClose={onClose}
      title='Import food from csv file'
      content={<UploadFood onClose={onClose} />}
    />
  )
}

UploadFoodModal.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default UploadFoodModal
