import axios from 'axios'
import authService from './auth-service'
import diaryService from './diary-service'
import foodService from './food-service'
import profileService from './profile-service'
import referencesService from './references-service'
import settingsService from './settings-service'

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers.common['Cache-Control'] = 'no-cache'
axios.defaults.headers.common['Pragma'] = 'no-cache'

axios.interceptors.response.use(
  response => response,
  error => {
    if (error?.response?.data) {
      const { name, code, message } = error.response.data
      throw { name, code, message }
    }
    throw error
  },
)

export { authService, diaryService, foodService, profileService, referencesService, settingsService }
