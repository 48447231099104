import PropTypes from 'prop-types'
import { createContext, useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { fetchData, fetchFoodItems, fetchReferences, logIn, logOut } from 'store'
import { selectDiaryFilter } from 'store/filters.slice'
import { selectUserId, signUp } from 'store/profile.slice'

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()

  const userId = useSelector(selectUserId)
  const diaryFilter = useSelector(selectDiaryFilter)

  const isAuthenticated = !!userId

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchReferences())
      dispatch(fetchFoodItems())
    }
  }, [isAuthenticated, dispatch])

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchData(diaryFilter.selectedDate))
    }
  }, [isAuthenticated, dispatch, diaryFilter.selectedDate])

  const origin = location.state?.from?.pathname || '/diary'

  const handleLogIn = async (username, password, persistent) =>
    await dispatch(logIn({ username, password, persistent, navigate: () => navigate(origin) }))

  const handleLogOut = async () => {
    if (isAuthenticated) {
      await dispatch(logOut())
    }
  }

  const handleSignUp = async (invitation, email, password) =>
    await dispatch(signUp({ invitation, email, password, navigate: () => navigate(origin) }))

  const value = {
    userId,
    origin,
    isAuthenticated,
    logIn: handleLogIn,
    logOut: handleLogOut,
    signUp: handleSignUp,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => useContext(AuthContext)

AuthProvider.propTypes = {
  children: PropTypes.node,
}
