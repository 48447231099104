import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { selectMenu } from 'store/settings.slice'

const Tabs = () => {
  const { pathname } = useLocation()
  const menuItems = useSelector(selectMenu)

  return (
    <nav className='tabs is-boxed is-centered'>
      <div className='container'>
        <ul>
          {menuItems.map(i => (
            <li key={i.id} className={`${i.url === pathname ? 'is-active' : ''}`}>
              <Link to={i.url}>{i.name}</Link>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  )
}

export default Tabs
