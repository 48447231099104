import { createSelector } from '@reduxjs/toolkit'
import { fetchData, selectDataStatus } from './diary.slice'
import { fetchFoodItems, selectFoodStatus } from './food.slice'
import { fetchProfile, logIn, logOut, selectProfileStatus } from './profile.slice'
import { fetchReferences, selectReferencesStatus } from './references.slice'
import { fetchSettings, selectSettingsStatus } from './settings.slice'
import { selectStatisticsStatus } from './statistics.slice'
import store from './store'

export { fetchProfile, logIn, logOut, fetchData, fetchFoodItems, fetchReferences, fetchSettings, store }

export const selectIsLoading = createSelector(
  [
    selectProfileStatus,
    selectReferencesStatus,
    selectFoodStatus,
    selectDataStatus,
    selectSettingsStatus,
    selectStatisticsStatus,
  ],
  (profileStatus, referencesStatus, foodStatus, dataStatus, settingsStatus, statisticsStatus) => {
    const statuses = [profileStatus, referencesStatus, foodStatus, dataStatus, settingsStatus, statisticsStatus]
    return statuses.some(x => x === 'loading')
  },
)
