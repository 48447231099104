import { DateSelector } from 'components/common'
import { Button } from 'components/core'
import { UploadFoodModal } from 'components/dialogs'
import { useAuth } from 'providers'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectProfileStatus } from 'store/profile.slice'

const Navbar = () => {
  const { userId, isAuthenticated, logOut } = useAuth()
  const status = useSelector(selectProfileStatus)

  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false)
  const [isUploadFoodOpen, setIsUploadFoodOpen] = useState(false)

  useEffect(() => {
    if (!isAuthenticated) setIsHamburgerOpen(false)
  }, [isAuthenticated])

  const closeUploadModal = useCallback(() => setIsUploadFoodOpen(false), [])

  return (
    <>
      <nav className='navbar'>
        <div className='navbar-brand'>
          <a className='navbar-item'>
            <img src='logo192.png' alt='Logo' />
          </a>
          {isAuthenticated && (
            <>
              <div className='navbar-item ml-auto'>
                <DateSelector />
              </div>
              <a
                role='button'
                aria-label='menu'
                aria-expanded='false'
                className={`navbar-burger ${isHamburgerOpen ? 'is-active' : ''}`}
                onClick={() => setIsHamburgerOpen(!isHamburgerOpen)}>
                <span aria-hidden='true'></span>
                <span aria-hidden='true'></span>
                <span aria-hidden='true'></span>
              </a>
            </>
          )}
        </div>
        <div className={`navbar-menu ${isHamburgerOpen ? 'is-active' : ''}`}>
          <div className='navbar-end'>
            {isAuthenticated && (
              <>
                <a className='navbar-item' onClick={() => setIsUploadFoodOpen(true)}>
                  Import
                </a>
                <span className='navbar-item'>{userId}</span>
              </>
            )}
            <span className='navbar-item'>
              {isAuthenticated && (
                <Button
                  className={`button is-info is-inverted is-outlined
                  ${!isHamburgerOpen ? 'is-small' : 'is-fullwidth'}
                  ${status === 'loading' ? 'is-loading' : ''}`}
                  content='Log out'
                  onClick={logOut}
                />
              )}
            </span>
          </div>
        </div>
      </nav>

      {isUploadFoodOpen && <UploadFoodModal isActive={isUploadFoodOpen} onClose={closeUploadModal} />}
    </>
  )
}

export default Navbar
