import { Button, FaIcon } from 'components/core'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectFoodStatus, uploadFoodItems } from 'store/food.slice'
import { selectAllFoodTypes } from 'store/references.slice'

const UploadFood = ({ onClose }) => {
  const limit = 32 * 1024

  const dispatch = useDispatch()
  const status = useSelector(selectFoodStatus)
  const allFoodTypes = useSelector(selectAllFoodTypes)

  const [state, setState] = useState({ file: null, typeId: allFoodTypes[0].id })

  const onFoodTypeSelected = e => {
    const selected = allFoodTypes.find(x => x.name === e.target.value)
    setState({ ...state, typeId: selected.id, type: selected })
  }

  const onFileChange = event => {
    const file = event.target.files[0]
    if (file.size <= limit) setState({ ...state, file })
  }

  const onUpload = async () => {
    if (state.file) {
      await dispatch(uploadFoodItems({ file: state.file, type: state.typeId }))
      onClose()
    }
  }

  return (
    <div className='block'>
      <div className='field is-fullwidth'>
        <label className='label'>Type</label>
        <div className='control'>
          <div className='select is-fullwidth'>
            <select value={state.type?.name || ''} onChange={onFoodTypeSelected}>
              {allFoodTypes.map(x => (
                <option key={x.id}>{x.name}</option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className='field'>
        <div className='file is-info has-name is-fullwidth'>
          <label className='file-label'>
            <input name='file' type='file' className='file-input' accept='.csv' onChange={onFileChange} />
            <span className='file-cta'>
              <FaIcon icon='fa-upload' />
              <span className='file-label ml-2'>File</span>
            </span>
            <span className='file-name'>{state.file?.name}</span>
          </label>
        </div>
      </div>

      <div className='buttons is-right'>
        <Button
          className={`is-info ${status === 'loading-modal' ? 'is-loading' : ''}`}
          content='Upload'
          onClick={onUpload}
        />
        <Button content='Cancel' onClick={onClose} />
      </div>
    </div>
  )
}

UploadFood.propTypes = {
  onClose: PropTypes.func,
}

export default UploadFood
