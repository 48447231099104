import PropTypes from 'prop-types'
import { FaIcon } from '..'

const Button = ({ content, icon, onClick, className }) => {
  return (
    <button className={`button ${className}`} onClick={onClick}>
      {icon && <FaIcon icon={icon} />}
      {content}
    </button>
  )
}

Button.defaultProps = {
  onClick: () => {},
}

Button.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  icon: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}

export default Button
