import { FaIcon } from 'components/core'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'

const Searchbox = ({ value, onChange, onEnterKeyUp, placeholder, className, autoFocus }) => {
  const [model, setModel] = useState(value)

  const input = useRef(null)

  useEffect(() => {
    if (autoFocus) input.current.focus()
  }, [autoFocus])

  useEffect(() => {
    setModel(value)
  }, [value])

  const handleChange = x => {
    if (x !== model) {
      setModel(x)
      onChange(x)
    }
  }

  const handleKeyUp = e => {
    if (e.keyCode === 13) {
      e.stopPropagation()
      onEnterKeyUp()
    }
  }

  return (
    <p className={`control has-icons-left has-icons-right ${className}`}>
      <input
        className='input'
        type='text'
        placeholder={placeholder}
        value={model}
        onChange={e => handleChange(e.target.value?.toLowerCase())}
        onKeyUp={handleKeyUp}
        autoFocus={autoFocus}
        ref={input}
      />

      <FaIcon className='is-left' icon='search' />

      <span className='icon is-right'>
        <button className='delete' onClick={() => handleChange('')}></button>
      </span>
    </p>
  )
}

Searchbox.defaultProps = {
  value: '',
  placeholder: 'Search...',
  onChange: () => {},
  onEnterKeyUp: () => {},
}

Searchbox.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onEnterKeyUp: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  autoFocus: PropTypes.bool,
}

export default Searchbox
