import { configureStore } from '@reduxjs/toolkit'
import diaryReducer from './diary.slice'
import filtersReducer from './filters.slice'
import foodReducer from './food.slice'
import profileReducer from './profile.slice'
import referencesReducer from './references.slice'
import settingsReducer from './settings.slice'
import statisticsReducer from './statistics.slice'

const store = configureStore({
  reducer: {
    settings: settingsReducer,
    filters: filtersReducer,
    profile: profileReducer,
    references: referencesReducer,
    food: foodReducer,
    diary: diaryReducer,
    statistics: statisticsReducer,
  },
})

export default store
