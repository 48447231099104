import 'assets/styles/app.scss'
import { Loader, NotificationBar, ProtectedRoute } from 'components/common'
import { Diary, Food, Footer, Hero, Home, Meal, Profile } from 'components/views'
import { AuthProvider } from 'providers'
import { useSelector } from 'react-redux'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import { selectIsLoading } from 'store'

const App = () => {
  const isLoading = useSelector(selectIsLoading)

  return (
    <Router>
      <AuthProvider>
        <Hero />
        <Loader />
        <div className={isLoading ? 'is-disabled' : ''}>
          <Routes>
            <Route path='/' exact element={<Home />} />
            <Route element={<ProtectedRoute />}>
              <Route path='/diary' exact element={<Diary />} />
              <Route path='/food' element={<Food />} />
              <Route path='/meal' element={<Meal />} />
              <Route path='/profile' element={<Profile />} />
            </Route>
          </Routes>
        </div>
        <Footer />
        <NotificationBar />
      </AuthProvider>
    </Router>
  )
}

export default App
