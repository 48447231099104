export const emptyArray = []

export const emptyObject = {}

export const isEmpty = obj =>
  obj === undefined ||
  obj === null ||
  obj === '' ||
  (Array.isArray(obj) && !obj.length) ||
  (typeof obj === 'object' && !Object.entries(obj).length)

export const hasEmpty = (...args) => !args.length || args.some(x => isEmpty(x))
