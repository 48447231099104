import PropTypes from 'prop-types'
import { useState } from 'react'

const FilterTabs = ({ items, selectedItem, getId, getContent, onSelected }) => {
  const [selected, setSelected] = useState(selectedItem !== undefined ? selectedItem : items[0])

  const handleClick = x => {
    if (x !== selected) {
      setSelected(x)
      onSelected(x)
    }
  }

  return (
    <p className='panel-tabs'>
      {items.map(x => (
        <a key={getId(x)} className={getId(x) === getId(selected) ? 'is-active' : ''} onClick={() => handleClick(x)}>
          {getContent(x)}
        </a>
      ))}
    </p>
  )
}

FilterTabs.defaultProps = {
  getId: x => x,
  getContent: x => x,
  onSelected: () => {},
}

FilterTabs.propTypes = {
  items: PropTypes.array.isRequired,
  selectedItem: PropTypes.any,
  getId: PropTypes.func.isRequired,
  getContent: PropTypes.func.isRequired,
  onSelected: PropTypes.func.isRequired,
}

export default FilterTabs
