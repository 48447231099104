import axios from 'axios'

const API_URL = '/api/profiles'

const getProfile = async () => {
  const response = await axios.get(API_URL)
  return response.data
}

const updateProfile = async item => {
  const response = await axios.put(API_URL, item)
  return response.data
}

const profileService = { getProfile, updateProfile }

export default profileService
