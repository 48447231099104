import { Button } from 'components/core'
import { useAuth } from 'providers'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { selectProfileStatus } from 'store/profile.slice'
import { selectPasswordPolicy } from 'store/settings.slice'
import { isEmpty } from 'utils/helpers'

function SignUp() {
  const { signUp } = useAuth()
  const status = useSelector(selectProfileStatus)
  const passwordPolicy = useSelector(selectPasswordPolicy)

  const [state, setState] = useState({ invitation: '', email: '', password: '', passwordRepeat: '' })
  const [errors, setErrors] = useState({})

  const isEmailValid = email =>
    email
      .toLowerCase()
      .match(
        /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
      )

  const isPasswordValid = password =>
    passwordPolicy &&
    password.length >= passwordPolicy.minLength &&
    (!passwordPolicy.digits || /[\d]/.test(password)) &&
    (!passwordPolicy.lower || /[a-z]/.test(password)) &&
    (!passwordPolicy.upper || /[A-Z]/.test(password)) &&
    (!passwordPolicy.specials || passwordPolicy.specialCharacters.some(x => password.includes(x)))

  const validate = () => {
    var result = []

    if (state.password !== state.passwordRepeat) {
      result.push({ code: 'password', error: 'Passwords mismatch' })
      result.push({ code: 'passwordRepeat', error: 'Passwords mismatch' })
    }

    Object.keys(state).forEach(x => {
      if (isEmpty(state[x])) result.push({ code: x, error: 'Field is required' })
    })

    if (!isEmailValid(state.email)) result.push({ code: 'email', error: 'Invalid email format' })
    if (!isPasswordValid(state.password)) result.push({ code: 'password', error: passwordPolicy.message })

    const hasErrors = result.length > 0
    if (!hasErrors) setErrors({})
    else {
      const e = result.reduce((acc, x) => {
        acc[x.code] = x.error
        return acc
      }, {})
      setErrors(e)
    }

    return !hasErrors
  }

  const handleSignUp = () => {
    if (validate()) signUp(state.invitation, state.email, state.password)
  }

  const field = (name, label, value, type, autoFocus = false) => (
    <div className='field'>
      <label className='label'>{label}</label>
      <div className='control'>
        <input
          className='input'
          type={type}
          value={value || ''}
          onChange={e => setState({ ...state, [name]: e.target.value })}
          onKeyUp={e => e.code === 'Enter' && handleSignUp()}
          autoFocus={autoFocus}
        />
      </div>
      {errors[name] && <p className='help is-danger'>{errors[name]}</p>}
    </div>
  )

  return (
    <div className='block'>
      {field('invitation', 'Invitation Code', state.invitation, 'text', true)}
      {field('email', 'Email', state.email, 'text')}
      {field('password', 'Password', state.password, 'password')}
      {field('passwordRepeat', 'Repeat Password', state.passwordRepeat, 'password')}

      <div className='field is-grouped'>
        <div className='field is-fullwidth'></div>
        <Button
          className={`is-info ${status === 'loading' ? 'is-loading' : ''}`}
          content='Sign Up'
          onClick={handleSignUp}
        />
      </div>
    </div>
  )
}

export default SignUp
