import { Button } from 'components/core'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { foodSelectorFilterChanged, selectFoodSelectorFilter } from 'store/filters.slice'
import { selectFoodInfo } from 'store/food.slice'
import { selectAllFoodTypes } from 'store/references.slice'
import { capitalize } from 'utils/helpers'
import AddButton from '../add-button/add-button'
import FilterTabs from '../filter-tabs/filter-tabs'
import Searchbox from '../searchbox/searchbox'

const FoodSelector = ({ onSelected, onAdd, autoFocus }) => {
  const dispatch = useDispatch()

  const foodTypes = useSelector(selectAllFoodTypes)
  const filter = useSelector(selectFoodSelectorFilter)
  const food = useSelector(selectFoodInfo)

  const tabs = [{ id: null, name: 'All' }, ...foodTypes]

  const searchResultsVisible = filter.searchCriteria?.length > 0
  const searchResult = food.filter(
    x =>
      searchResultsVisible &&
      (filter.type === null || filter.type === undefined || x.typeId === filter.type) &&
      (x.nameLower?.includes(filter.searchCriteria) || x.aliasLower?.includes(filter.searchCriteria)),
  )

  const handleSelected = (foodItem, value) => {
    if (value) {
      onSelected(foodItem, value)
      dispatch(foodSelectorFilterChanged({ ...filter, searchCriteria: '' }))
    }
  }

  const handleAdd = () => {
    const existing = searchResult.find(x => x.nameLower === filter.searchCriteria)
    onAdd(existing || { name: capitalize(filter.searchCriteria) })
  }

  return (
    <nav className='panel'>
      <div className='panel-block'>
        <Searchbox
          value={filter.searchCriteria}
          onChange={x => dispatch(foodSelectorFilterChanged({ ...filter, searchCriteria: x }))}
          onEnterKeyUp={() => searchResultsVisible && searchResult && handleAdd()}
          autoFocus={autoFocus}
        />
        <Button className='ml-2' content='Add' onClick={handleAdd} />
      </div>

      {searchResultsVisible && (
        <>
          <FilterTabs
            items={tabs}
            selectedItem={tabs.find(x => x.id === filter.type)}
            getId={x => x.id}
            getContent={x => x.name}
            onSelected={x => dispatch(foodSelectorFilterChanged({ ...filter, type: x.id }))}
          />

          {searchResult.map(x => (
            <div key={x.id} className='panel-block is-flex is-justify-content-space-between'>
              <div>
                <span>{x.name}</span>
                <div className='is-white-space-nowrap is-size-7'>
                  {x.calories} kCal, {x.protein} / {x.fat} / {x.carbohydrate}
                </div>
              </div>

              <AddButton onAdd={v => handleSelected(x, v)} />
            </div>
          ))}
        </>
      )}
    </nav>
  )
}

FoodSelector.defaultProps = {
  onSelected: () => {},
  onAdd: () => {},
}

FoodSelector.propTypes = {
  onSelected: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool,
}

export default FoodSelector
