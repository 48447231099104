import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectLimits, selectTimeZoneId, updateProfile } from 'store/profile.slice'
import { selectAllTimeZones, selectTimeZones } from 'store/references.slice'
import { calculateCalories } from 'utils/helpers'

const Profile = () => {
  const dispatch = useDispatch()

  const allTimeZones = useSelector(selectAllTimeZones)
  const timeZones = useSelector(selectTimeZones)
  const timeZoneId = useSelector(selectTimeZoneId)
  const limits = useSelector(selectLimits)

  const calculateLimits = useCallback(
    ({ protein, fat, carbohydrate }) => ({
      min: calculateCalories(protein.min, fat.min, carbohydrate.min),
      normal: calculateCalories(protein.normal, fat.normal, carbohydrate.normal),
      max: calculateCalories(protein.max, fat.max, carbohydrate.max),
    }),
    [],
  )

  const [state, setState] = useState({ limits, timeZone: timeZones[timeZoneId], calc: calculateLimits(limits) })

  useEffect(
    () => setState({ limits, timeZone: timeZones[timeZoneId], calc: calculateLimits(limits) }),
    [limits, timeZones, timeZoneId, calculateLimits],
  )

  const onChange = async (item, itemName, fieldName, value) => {
    setState({ ...state, limits: { ...state.limits, [itemName]: { ...item, [fieldName]: value || '0' } } })
  }

  const onTimeZoneSelected = async e => {
    const selected = allTimeZones.find(x => x.displayName === e.target.value)
    await dispatch(updateProfile({ limits: state.limits, timeZoneId: selected.id }))
  }

  const onBlur = async (item, name, value) => {
    if (item[name] !== value) {
      await dispatch(updateProfile({ limits: state.limits, timeZoneId: state.timeZone.id }))
    }
  }

  const field = (item, itemName, name, label, value, autoFocus) => (
    <div className='field is-horizontal mr-2'>
      <div className='field-label is-normal'>
        <label className='label has-text-weight-medium'>{label}</label>
      </div>
      <div className='control'>
        <input
          className='input'
          type='number'
          name={name}
          placeholder={label}
          value={value || ''}
          onChange={e => onChange(item, itemName, name, e.target.value)}
          onBlur={e => onBlur(item, name, +e.target.value)}
          autoFocus={autoFocus}
        />
      </div>
    </div>
  )

  const calculatedItem = (value, title) =>
    value ? (
      <span>
        {title}: <b>{value} </b>
      </span>
    ) : (
      <></>
    )

  const calculatedValues = calculated =>
    calculated && (
      <div className='message is-info is-small'>
        <div className='message-body'>
          <span>CALCULATED: </span>
          {calculatedItem(calculated.min, 'min')}
          {calculatedItem(calculated.normal, 'normal')}
          {calculatedItem(calculated.max, 'max')}
        </div>
      </div>
    )

  const section = (item, name, title, calculated) => (
    <>
      <h1 className='title heading has-text-weight-bold is-size-7'>{title} / day</h1>

      {calculatedValues(calculated)}

      <div className='field'>
        <div className='field-body is-flex'>
          {field(item, name, 'min', 'Min', item.min, name === 'calories')}
          {field(item, name, 'normal', 'Normal', item.normal)}
          {field(item, name, 'max', 'Max', item.max)}
        </div>
      </div>
    </>
  )

  const timeZone = () => (
    <section key='timezone' className='section p-3'>
      <div className='box'>
        <h1 className='title heading has-text-weight-bold is-size-7'>Timezone</h1>
        <div className='field is-fullwidth'>
          <div className='control'>
            <div className='select is-fullwidth'>
              <select value={state.timeZone?.displayName || ''} onChange={onTimeZoneSelected}>
                {allTimeZones.map(x => (
                  <option key={x.id}>{x.displayName}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    </section>
  )

  return (
    state && (
      <>
        {timeZone()}
        <section className='section p-3'>
          <div className='box'>
            {section(state.limits.calories, 'calories', 'Calories', state.calc)}
            {section(state.limits.protein, 'protein', 'Protein')}
            {section(state.limits.fat, 'fat', 'Fat')}
            {section(state.limits.carbohydrate, 'carbohydrate', 'Carbohydrate')}
          </div>
        </section>
      </>
    )
  )
}

export default Profile
