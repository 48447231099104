import { createSlice } from '@reduxjs/toolkit'
import { getTodayDateString } from 'utils/helpers'

const initialState = {
  diaryFilter: {
    selectedDate: getTodayDateString(),
    isToday: true,
  },
  foodSelectorFilter: {
    searchCriteria: undefined,
    type: undefined,
  },
  foodFilter: {
    searchCriteria: undefined,
    type: undefined,
  },
  mealFilter: {
    searchCriteria: undefined,
  },
}

const slice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    diaryFilterChanged(state, action) {
      state.diaryFilter = action.payload
    },
    foodSelectorFilterChanged(state, action) {
      state.foodSelectorFilter = action.payload
    },
    foodFilterChanged(state, action) {
      state.foodFilter = action.payload
    },
    mealFilterChanged(state, action) {
      state.mealFilter = action.payload
    },
  },
})

export default slice.reducer

export const { diaryFilterChanged, foodSelectorFilterChanged, foodFilterChanged, mealFilterChanged } = slice.actions

export const selectDiaryFilter = state => state.filters.diaryFilter
export const selectFoodSelectorFilter = state => state.filters.foodSelectorFilter
export const selectFoodFilter = state => state.filters.foodFilter
export const selectMealFilter = state => state.filters.mealFilter
