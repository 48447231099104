import axios from 'axios'

const API_URL = '/api/auth'

const logIn = async (username, password, persistent) => {
  const response = await axios.post(API_URL, { username, password, persistent })
  return response.data
}

const signUp = async (invitation, email, password) => {
  const response = await axios.post(`${API_URL}/signup`, { invitation, email, password })
  return response.data
}

const logOut = async () => {
  const response = await axios.post(`${API_URL}/logout`)
  return response.data
}

const authService = { logIn, signUp, logOut }

export default authService
