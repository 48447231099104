import { useAuth } from 'providers'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { selectLoginMenu } from 'store/settings.slice'

const Home = () => {
  const { origin, isAuthenticated } = useAuth()

  const tabs = useSelector(selectLoginMenu)
  const [selectedTab, setSelectedTab] = useState(tabs[0])

  if (isAuthenticated) {
    return <Navigate to={origin} replace />
  }

  const tab = x => (
    <li key={x.id} className={`${x.id === selectedTab.id ? 'is-active' : ''}`}>
      <a onClick={() => setSelectedTab(x)}>
        <span>{x.name}</span>
      </a>
    </li>
  )

  return (
    <>
      <section className='section login'>
        <div className='container'>
          <div className='card'>
            <div className='card-header'>
              <div className='tabs is-fullwidth'>{<ul>{tabs.map(x => tab(x))}</ul>}</div>
            </div>
            <div className='card-content'>{selectedTab.render()}</div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Home
