import { createSelector, createSlice } from '@reduxjs/toolkit'
import { hasEmpty } from 'utils/helpers'
import { selectDayInfo } from './diary.slice'
import { selectLimits } from './profile.slice'

const initialState = {}

const slice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {},
})

export default slice.reducer

export const selectStatisticsStatus = createSelector(
  state => state.statistics.status,
  status => status,
)

// export const {} = slice.actions

export const selectDayStatistics = createSelector([selectDayInfo, selectLimits], (data, limits) => {
  if (hasEmpty(data, limits)) return { calories: 0, protein: 0, fat: 0, carbohydrate: 0 }

  const result = data.reduce(
    (acc, x) => {
      return {
        calories: acc.calories + x.calories,
        protein: acc.protein + x.protein,
        fat: acc.fat + x.fat,
        carbohydrate: acc.carbohydrate + x.carbohydrate,
      }
    },
    { calories: 0, protein: 0, fat: 0, carbohydrate: 0 },
  )

  Object.keys(result).forEach(x => {
    const target = limits[x]?.normal
    result[`${x}Percent`] = target ? Math.round((result[x] / target) * 100) : 0
  })

  return result
})

export const selectTodaysLevel = createSelector([selectDayStatistics, selectLimits], (stats, limits) => {
  if (hasEmpty(stats, limits)) return 'info'

  if (stats.calories <= limits.calories.min) return 'info'
  else if (stats.calories <= limits.calories.normal) return 'success'
  else if (stats.calories <= limits.calories.max) return 'warning'
  return 'danger'
})
